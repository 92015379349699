// WRITE ARTICLE
export const addContactInfo = {
  order: 5,
  name: "Add contact info",
  subcategory: "Companies & contacts",
  markdown: `# Add contact info

  {% callout type="caution" %}
  **Important:** Contact info is intentionally private; it cannot be {% inlineRouterLink articleId="declassify-datapoints" %}declassified{% /inlineRouterLink %}.
  {% /callout %}`,
};
